<template>
  <section class="conversation-area">
    <div class="conversation" ref="conversationContainer">
      <div v-for="(msg, index) in conversation" :key="index" :class="msg.role">
        <div class="msg-wrapper">
          <span class="msg-role">{{ msg.role }}</span>
          <span class="ellipsis" v-if="msg == ''">...</span>
          <span class="msg-content" v-else v-html="msg.content.replace(/(?:\r\n|\r|\n)/g, '<br>')"></span>
        </div>
      </div>
    </div>
  </section>
  <section class="entry-area">
    <div class="entry-wrapper">
      <textarea
        ref="messageInput"
        v-model="newMessage"
        rows="1"
        placeholder="Enter new message"
        @input="autoResize"
        @keydown.enter.prevent="submitUserMessage"
        autofocus
      ></textarea>
      <button @click="submitUserMessage">Send Message</button>
      <button @click="resetConversation">Reset Conversation</button>
    </div>
  </section>
</template>

<script>
import { ref, computed, onMounted, toRaw, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { systemMessagesContactReport } from '@/openai.config';
import { getAssistantResponse } from '@/api/openai';

export default {
  name: 'MyComponent',

  setup() {
    const store = useStore();
    const conversation = computed(() => store.getters.conversation);
    const newMessage = ref('');
    const isLoading = ref(false);
    const messageInput = ref(null);
    const conversationContainer = ref(null); // Add conversationContainer ref

    const scrollToBottom = async () => {
      await nextTick(); // Wait for the DOM to update
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    };

    watch(conversation, () => {
      scrollToBottom(); // Trigger the scroll to bottom whenever the conversation changes
    }, { deep: true }); // Add deep:true to watch changes in the conversation array

    const autoResize = () => {
      messageInput.value.style.height = 'auto';
      if (messageInput.value) {
        messageInput.value.style.height = messageInput.value.scrollHeight + 'px';
      }
    };

    onMounted(() => {
      console.log('MyComponent.vue mounted');
      autoResize(); // Resize the textarea after the component is mounted

    });

    const resetConversation = () => {
      store.dispatch('clearConversation');
    };

    const submitUserMessage = async () => {
      if (isLoading.value) return; // Disable submission if loading is in progress
      if (newMessage.value.trim() === '') return; // Avoid sending empty messages

      const userMessage = { role: 'user', content: newMessage.value };
      store.dispatch('addMessage', userMessage);
      newMessage.value = ''; // Clear the textarea immediately
      
      await nextTick(); // Wait for the DOM to update
      autoResize(); // Resize the textarea after clearing it

      // Create a copy of the conversation without reactivity
      const rawConversation = systemMessagesContactReport.concat(conversation.value.map(msg => toRaw(msg)));

      isLoading.value = true;
      const assistantMessage = {
        role: 'assistant',
        content: '',
      };
      const assistantMessageIndex = conversation.value.length;
      store.dispatch('addMessage', assistantMessage);
       try {
        const streamAssistantResponse = async (responseChunk) => {
          store.dispatch('updateMessage', { index: assistantMessageIndex, content: responseChunk });
        };

        await getAssistantResponse(rawConversation, streamAssistantResponse, "o3-mini");
      } catch (error) {
        console.error('Error while calling OpenAI API:', error);
      }
      isLoading.value = false;
    };

    return {
      conversation,
      newMessage,
      submitUserMessage,
      resetConversation,
      isLoading,
      messageInput,
      autoResize,
      conversationContainer,
    };
  },
};
</script>

<style>

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: #444654;
}

.conversation-area {
  width: 100%;
  margin: 0 auto;
}

.conversation {
  width: 100%;
  padding-bottom: 116px;
  margin-bottom: 0;
  overflow-y: auto;
}

.user,
.assistant {
  padding: 2rem;
  width: 100%;
  color: rgb(209, 213, 219);
  margin-bottom: 0.5rem;
  line-height: 1.5;
  font-family: sans-serif;
}

.user .msg-wrapper,
.assistant .msg-wrapper {
  display: flex;
  max-width: 750px;
  margin: 0 auto;
}

.msg-role {
  display: block;
  width: 6rem;
  margin-left: -7rem;
  text-transform: capitalize;
  font-weight: 700;
  color: #ffffff;
  text-align: right;
}

.msg-content {
  display: block;
  margin-left: 1rem;
  width: calc(100% - 6rem);
}

.user {
  background-color: rgb(52, 53, 65);
}

.assistant {
  background-color: rgba(0,0,0,0);
}

@keyframes ellipsis {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ellipsis {
  display: inline-block;
  font-weight: bold;
  animation: ellipsis 1.5s steps(3, start) infinite;
}

.entry-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgb(52, 53, 65);
  padding: 1rem;
  padding-bottom: 2rem;
}

.entry-wrapper {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
}

textarea {
  width: 100%; /* Make textarea 100% wide */
  resize: none; /* Disable resize by dragging the corner */
  overflow: hidden;
  padding: 0.85rem 1rem;
  border: 0;
  border-radius: 6px;
  color: rgb(255,255,255);
  background-color: rgba(255,255,255,0.1);
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  font-size: 1rem;
  line-height: 1.5;
}
</style>
